"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("@/api");
const namespace_1 = require("@cube-creator/core/namespace");
const initialState = {
    collection: null,
};
const getters = {};
const actions = {
    async fetchCollection(context) {
        var _a;
        const entrypoint = context.rootState.sharedDimensions.entrypoint;
        const collectionURI = (_a = entrypoint === null || entrypoint === void 0 ? void 0 : entrypoint.get(namespace_1.md.hierarchies)) === null || _a === void 0 ? void 0 : _a.id;
        if (!collectionURI)
            throw new Error('Missing hierarchies collection in entrypoint');
        const collection = await api_1.api.fetchResource(collectionURI.value);
        context.commit('storeCollection', collection);
    },
};
const mutations = {
    storeCollection(state, collection) {
        state.collection = collection || null;
    },
};
exports.default = {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
