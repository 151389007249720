"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const rdfine_1 = require("@tpluscode/rdfine");
const namespace_1 = require("@cube-creator/core/namespace");
const strict_1 = require("@tpluscode/rdf-ns-builders/strict");
function pathMixin(base) {
    class Impl extends base {
    }
    __decorate([
        (0, rdfine_1.property)({ path: strict_1.sh.inversePath })
    ], Impl.prototype, "inversePath", void 0);
    return Impl;
}
function nextInHierarchyMixin(base) {
    class Impl extends base {
    }
    __decorate([
        rdfine_1.property.literal({ path: strict_1.schema.name })
    ], Impl.prototype, "name", void 0);
    __decorate([
        rdfine_1.property.resource({ path: namespace_1.meta.nextInHierarchy, as: [nextInHierarchyMixin] })
    ], Impl.prototype, "nextInHierarchy", void 0);
    __decorate([
        rdfine_1.property.resource({ path: strict_1.sh.path, as: [pathMixin] })
    ], Impl.prototype, "property", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: strict_1.sh.targetClass, values: 'array' })
    ], Impl.prototype, "targetType", void 0);
    return Impl;
}
function hierarchyLevelMixin(base) {
    class Impl extends base {
    }
    __decorate([
        rdfine_1.property.literal({ path: strict_1.schema.name })
    ], Impl.prototype, "name", void 0);
    __decorate([
        rdfine_1.property.resource({ path: namespace_1.meta.nextInHierarchy, as: [nextInHierarchyMixin] })
    ], Impl.prototype, "nextInHierarchy", void 0);
    return Impl;
}
function mixin(base) {
    class Impl extends hierarchyLevelMixin(base) {
    }
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.md.sharedDimension })
    ], Impl.prototype, "dimension", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.meta.hierarchyRoot, values: 'array' })
    ], Impl.prototype, "hierarchyRoot", void 0);
    return Impl;
}
exports.default = mixin;
mixin.appliesTo = namespace_1.meta.Hierarchy;
