"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const rdfine_1 = require("@tpluscode/rdfine");
const namespace_1 = require("@cube-creator/core/namespace");
function mixin(base) {
    class Impl extends base {
        get _additionalActions() {
            return {
                createLiteralColumnMapping: namespace_1.cc.CreateLiteralColumnMappingAction,
                createReferenceColumnMapping: namespace_1.cc.CreateReferenceColumnMappingAction,
            };
        }
    }
    __decorate([
        rdfine_1.property.resource({ values: 'array', path: namespace_1.cc.columnMapping })
    ], Impl.prototype, "columnMappings", void 0);
    return Impl;
}
exports.default = mixin;
mixin.appliesTo = namespace_1.cc.Table;
