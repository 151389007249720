"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCustomElement = void 0;
const vue_custom_element_1 = require("./vue-custom-element");
function createCustomElement(tag) {
    return async function (componentModule) {
        const component = 'default' in componentModule ? componentModule.default : componentModule;
        const customElement = (0, vue_custom_element_1.defineCustomElement)(component, { shadowRoot: false });
        defineOnce(tag, customElement);
    };
}
exports.createCustomElement = createCustomElement;
function defineOnce(tagName, customElement) {
    const existingCustomElement = customElements.get(tagName);
    return typeof existingCustomElement !== 'undefined'
        ? existingCustomElement
        : customElements.define(tagName, customElement);
}
