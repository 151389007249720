"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'BMessage',
    props: {
        type: {
            type: String,
            default: undefined,
        },
        size: {
            type: String,
            default: undefined,
        },
        title: {
            type: String,
            default: undefined,
        },
        text: {
            type: String,
            default: undefined,
        },
    },
});
