"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HydraOperationFormWithRaw = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
const HydraOperationFormBase_1 = require("@/customElements/HydraOperationFormBase");
require("./HydraOperationForm");
require("./HydraRawRdfForm");
let HydraOperationFormWithRaw = class HydraOperationFormWithRaw extends HydraOperationFormBase_1.HydraOperationFormBase {
    constructor() {
        super(...arguments);
        this.sourceMode = false;
    }
    createRenderRoot() {
        return this;
    }
    renderForm() {
        if (this.sourceMode) {
            return (0, lit_1.html) `
        <cc-hydra-raw-rdf-form
          .operation="${this.operation}"
          .resource="${this.resource}"
          .shape="${this.shape}"
          .error="${this.error}"
          .submitting="${this.submitting}"
          submit-label="${this.submitLabel}"
          .show-cancel="${this.showCancel}"
        ></cc-hydra-raw-rdf-form>
        <div class="mt-4 has-text-right">
          <cc-o-button @click="${this.toggleMode}" icon-left="chevron-left" label="Back to form (basic)">
          </cc-o-button>
        </div>
      `;
        }
        return (0, lit_1.html) `
      <cc-hydra-operation-form
        .operation="${this.operation}"
        .resource="${this.resource}"
        .shape="${this.shape}"
        .error="${this.error}"
        .submitting="${this.submitting}"
        submit-label="${this.submitLabel}"
        .show-cancel="${this.showCancel}"
      ></cc-hydra-operation-form>
      <div class="mt-4 has-text-right">
        <cc-o-button icon-right="exclamation-triangle" @click="${this.toggleMode}" variant="text" label="Edit raw RDF (advanced)">
        </cc-o-button>
      </div>
    `;
    }
    toggleMode() {
        if (this.sourceMode) {
            this.resource = this.editor.value;
        }
        else {
            this.resource = this.form.value;
        }
        this.sourceMode = !this.sourceMode;
    }
};
__decorate([
    (0, decorators_js_1.property)({ type: Boolean })
], HydraOperationFormWithRaw.prototype, "sourceMode", void 0);
__decorate([
    (0, decorators_js_1.query)('cc-hydra-raw-rdf-form')
], HydraOperationFormWithRaw.prototype, "editor", void 0);
__decorate([
    (0, decorators_js_1.query)('cc-hydra-operation-form')
], HydraOperationFormWithRaw.prototype, "form", void 0);
HydraOperationFormWithRaw = __decorate([
    (0, decorators_js_1.customElement)('cc-hydra-operation-form-with-raw')
], HydraOperationFormWithRaw);
exports.HydraOperationFormWithRaw = HydraOperationFormWithRaw;
