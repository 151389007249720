"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'ExternalTermLink',
    props: {
        term: {
            type: Object,
            required: true,
        },
    },
    computed: {
        uri() {
            var _a, _b;
            if ('termType' in this.term) {
                return this.term.value;
            }
            return ((_a = this.term.canonical) === null || _a === void 0 ? void 0 : _a.value) || ((_b = this.term.id) === null || _b === void 0 ? void 0 : _b.value);
        },
    },
});
