"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'HydraOperationButton',
    props: {
        operation: {
            type: Object,
            default: null,
        },
        to: {
            type: Object,
            default: null,
        },
        variant: {
            type: String,
            default: 'text',
        },
        size: {
            type: String,
            default: 'small',
        },
        icon: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
    },
    emits: ['click'],
    computed: {
        tag() {
            return this.to ? 'router-link' : 'button';
        },
        iconName() {
            return this.icon || guessIcon(this.operation);
        },
    },
});
function guessIcon(operation) {
    if (!operation)
        return null;
    switch (operation.method) {
        case 'POST':
            return 'plus';
        case 'PATCH':
        case 'PUT':
            return 'pencil-alt';
        case 'DELETE':
            return 'trash';
        default:
            return null;
    }
}
