"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const rdf_properties_1 = require("@/rdf-properties");
const initialState = {
    language: ['en', '*'],
    messages: [],
    loading: false,
    commonRDFProperties: [],
    selectedLanguage: 'en',
};
const getters = {};
const actions = {
    dismissMessage(context, message) {
        context.commit('removeMessage', message);
    },
    showMessage(context, message) {
        context.commit('pushMessage', message);
    },
    async loadCommonRDFProperties(context) {
        const properties = await (0, rdf_properties_1.loadCommonProperties)();
        context.commit('storeCommonRDFProperties', properties);
    },
    selectLanguage(context, language) {
        context.commit('storeSelectedLanguage', language);
    },
};
const mutations = {
    pushMessage(state, message) {
        state.messages = [...state.messages, message];
    },
    removeMessage(state, message) {
        state.messages = state.messages.filter((m) => m !== message);
    },
    setLoading(state, isLoading) {
        state.loading = isLoading;
    },
    storeCommonRDFProperties(state, properties) {
        state.commonRDFProperties = properties;
    },
    storeSelectedLanguage(state, language) {
        state.selectedLanguage = language;
    },
};
exports.default = {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
