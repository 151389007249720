"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function loading() {
    return { isLoading: true, isLoaded: false, data: null, error: null };
}
function loaded(data) {
    return { isLoading: false, isLoaded: true, data, error: null };
}
function notLoaded() {
    return { isLoading: false, isLoaded: false, data: null, error: 'Not loaded yet' };
}
function error(error) {
    return { isLoading: false, isLoaded: true, data: null, error };
}
exports.default = {
    error,
    loading,
    loaded,
    notLoaded,
};
