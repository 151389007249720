"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("@/api");
const getInitialState = () => ({
    dimension: null,
});
const getters = {};
const actions = {
    async fetchHierarchy(context, id) {
        context.commit('storeHierarchy', await api_1.api.fetchResource(id));
    },
    reset(context) {
        context.commit('reset');
    },
};
const mutations = {
    storeHierarchy(state, hierarchy) {
        state.hierarchy = hierarchy || null;
    },
    reset(state) {
        Object.assign(state, getInitialState());
    },
};
exports.default = {
    namespaced: true,
    state: getInitialState(),
    getters,
    actions,
    mutations,
};
