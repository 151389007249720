"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createProxy = void 0;
const handler = {
    get(env, prop) {
        if (prop === 'has') {
            return (name) => {
                return !!env[name];
            };
        }
        if (prop === 'maybe') {
            return env;
        }
        if (prop === 'production') {
            return env.NODE_ENV !== 'development';
        }
        const value = env[prop];
        if (!value) {
            throw new Error(`Missing environment variable ${prop}`);
        }
        return value;
    },
    set(obj, prop, value) {
        obj[prop] = value;
        return true;
    },
};
const createProxy = () => new Proxy(process.env, handler);
exports.createProxy = createProxy;
exports.default = (0, exports.createProxy)();
