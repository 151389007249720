"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'SharedDimensionTags',
    props: {
        dimension: {
            type: Object,
            required: true,
        }
    },
});
