"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.displayToast = void 0;
const oruga_next_1 = require("@oruga-ui/oruga-next");
function displayToast(message) {
    const { oruga } = (0, oruga_next_1.useProgrammatic)();
    oruga.notification.open({
        rootClass: 'toast-notification',
        position: 'top',
        duration: 2000,
        ...message,
    });
}
exports.displayToast = displayToast;
