"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'OidcCallback',
    async mounted() {
        try {
            const redirectPath = await this.$store.dispatch('auth/oidcSignInCallback');
            this.$router.push(redirectPath);
        }
        catch (err) {
            console.error(err);
            this.$router.push({ name: 'OidcError' });
        }
    },
});
