"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const vue_1 = require("vue");
const namespace_1 = require("@cube-creator/core/namespace");
require("@/customElements/HydraOperationForm");
const use_hydra_form_1 = require("@/use-hydra-form");
exports.default = (0, vue_1.defineComponent)({
    name: 'LiteralColumnMappingForm',
    props: {
        table: {
            type: Object,
            required: true,
        },
        source: {
            type: Object,
            required: true
        },
        columnMapping: {
            type: Object,
            default: null,
        },
        operation: {
            type: Object,
            required: true,
        },
        error: {
            type: Object,
            default: null,
        },
        isSubmitting: {
            type: Boolean,
            default: false,
        },
        submitLabel: {
            type: String,
            default: undefined,
        },
    },
    emits: ['submit', 'cancel'],
    setup(props) {
        var _a;
        const { columnMapping, operation, source } = (0, vue_1.toRefs)(props);
        const form = (0, use_hydra_form_1.useHydraForm)(operation);
        (_a = form.resource.value) === null || _a === void 0 ? void 0 : _a.addOut(rdf_ns_builders_1.rdf.type, namespace_1.cc.LiteralColumnMapping);
        (0, vue_1.watch)(form.shape, (shape) => {
            // Populate Column selector
            if (shape && source.value) {
                source.value.columns.forEach((column) => {
                    shape.pointer.node(column.id)
                        .addOut(rdf_ns_builders_1.schema.name, column.name)
                        .addOut(rdf_ns_builders_1.rdf.type, column.pointer.out(rdf_ns_builders_1.rdf.type));
                });
            }
            if (columnMapping.value) {
                form.resource.value = columnMapping.value.pointer;
            }
        });
        return {
            shape: form.shape,
            resource: form.resource,
        };
    },
});
