"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HydraOperationFormBase = void 0;
const lit_1 = require("lit");
const decorators_js_1 = require("lit/decorators.js");
require("./LoadingBlock");
class HydraOperationFormBase extends lit_1.LitElement {
    constructor() {
        super(...arguments);
        this.showCancel = false;
        this.submitting = false;
    }
    get _submitLabel() {
        var _a;
        return this.submitLabel || ((_a = this.operation) === null || _a === void 0 ? void 0 : _a.title);
    }
    createRenderRoot() {
        return this;
    }
    render() {
        if (!this.shape || !this.resource) {
            return (0, lit_1.html) `<cc-loading-block></cc-loading-block>`;
        }
        return this.renderForm();
    }
}
__decorate([
    (0, decorators_js_1.property)({ type: Object })
], HydraOperationFormBase.prototype, "operation", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Object })
], HydraOperationFormBase.prototype, "shape", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Object })
], HydraOperationFormBase.prototype, "resource", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Object })
], HydraOperationFormBase.prototype, "error", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean, attribute: 'show-cancel' })
], HydraOperationFormBase.prototype, "showCancel", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: Boolean })
], HydraOperationFormBase.prototype, "submitting", void 0);
__decorate([
    (0, decorators_js_1.property)({ type: String, attribute: 'submit-label' })
], HydraOperationFormBase.prototype, "submitLabel", void 0);
exports.HydraOperationFormBase = HydraOperationFormBase;
