"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiResourceMixin = void 0;
const common_1 = require("@/api/common");
const serializers_1 = require("@/store/serializers");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
function apiResourceMixin(rootUrl, separator) {
    function ApiResourceMixin(base) {
        class BaseClass extends base {
            get clientPath() {
                return this.id.value.replace(rootUrl, '').replace(/\//g, separator);
            }
            get actions() {
                return (0, common_1.commonActions)(this, this._additionalActions);
            }
            get displayLabel() {
                return this.pointer.out(rdf_ns_builders_1.rdfs.label, { language: serializers_1.displayLanguage }).value;
            }
        }
        return BaseClass;
    }
    ApiResourceMixin.shouldApply = true;
    return ApiResourceMixin;
}
exports.apiResourceMixin = apiResourceMixin;
