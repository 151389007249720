"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const filter_1 = require("clownface/filter");
const serializers_1 = require("@/store/serializers");
exports.default = (0, vue_1.defineComponent)({
    name: 'TermWithLanguage',
    props: {
        values: {
            type: Object,
            default: undefined,
        },
        selectedLanguage: {
            type: String,
            required: true,
        },
    },
    computed: {
        value() {
            var _a, _b, _c;
            if (Array.isArray(this.values)) {
                const term = ((_a = this.values) === null || _a === void 0 ? void 0 : _a.find(({ language }) => language === this.selectedLanguage)) ||
                    ((_b = this.values) === null || _b === void 0 ? void 0 : _b.find(({ language }) => !language));
                return term === null || term === void 0 ? void 0 : term.value;
            }
            const term = (_c = this.values) === null || _c === void 0 ? void 0 : _c.filter((0, filter_1.taggedLiteral)([
                this.selectedLanguage,
                ...serializers_1.displayLanguage,
                '*'
            ]));
            return term === null || term === void 0 ? void 0 : term.value;
        },
    },
});
