"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createUnlist = exports.UnlistJobMixin = exports.createPublish = exports.PublishJobMixin = exports.createTransform = exports.TransformJobMixin = exports.JobMixin = exports.JobErrorMixin = exports.isTransformJob = exports.isPublishJob = void 0;
const rdfine_1 = require("@tpluscode/rdfine");
const rdfs_1 = require("@rdfine/rdfs");
const schema_1 = require("@rdfine/schema");
const shacl_1 = require("@rdfine/shacl");
const namespace_1 = require("@cube-creator/core/namespace");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const initializer_1 = require("./lib/initializer");
function isPublishJob(job) {
    return job.types.has(namespace_1.cc.PublishJob);
}
exports.isPublishJob = isPublishJob;
function isTransformJob(job) {
    return job.types.has(namespace_1.cc.TransformJob);
}
exports.isTransformJob = isTransformJob;
function JobErrorMixin(base) {
    class Impl extends (0, rdfs_1.ResourceMixin)((0, schema_1.ThingMixin)(base)) {
    }
    __decorate([
        rdfine_1.property.resource({ path: namespace_1.cc.validationReport, as: [shacl_1.ValidationReportMixin] })
    ], Impl.prototype, "validationReport", void 0);
    return Impl;
}
exports.JobErrorMixin = JobErrorMixin;
function JobMixin(base) {
    class Impl extends (0, rdfs_1.ResourceMixin)((0, schema_1.ActionMixin)(base)) {
    }
    __decorate([
        rdfine_1.property.literal({ path: rdf_ns_builders_1.dcterms.created, type: Date, initial: () => new Date() })
    ], Impl.prototype, "created", void 0);
    __decorate([
        rdfine_1.property.literal({ path: rdf_ns_builders_1.dcterms.modified, type: Date })
    ], Impl.prototype, "modified", void 0);
    __decorate([
        rdfine_1.property.literal({ path: rdf_ns_builders_1.schema.name })
    ], Impl.prototype, "name", void 0);
    __decorate([
        rdfine_1.property.resource({ path: rdf_ns_builders_1.rdfs.seeAlso })
    ], Impl.prototype, "link", void 0);
    __decorate([
        rdfine_1.property.literal({ path: rdf_ns_builders_1.rdfs.comment, values: 'array' })
    ], Impl.prototype, "comments", void 0);
    return Impl;
}
exports.JobMixin = JobMixin;
JobMixin.appliesTo = namespace_1.cc.Job;
function TransformJobMixin(base) {
    class Impl extends (0, rdfs_1.ResourceMixin)((0, schema_1.ActionMixin)(base)) {
    }
    __decorate([
        rdfine_1.property.resource({ path: namespace_1.cc.tables })
    ], Impl.prototype, "tableCollection", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.cc.cubeGraph })
    ], Impl.prototype, "cubeGraph", void 0);
    __decorate([
        rdfine_1.property.resource({ path: namespace_1.cc.dimensionMetadata })
    ], Impl.prototype, "dimensionMetadata", void 0);
    return Impl;
}
exports.TransformJobMixin = TransformJobMixin;
TransformJobMixin.appliesTo = namespace_1.cc.TransformJob;
exports.createTransform = (0, initializer_1.initializer)(TransformJobMixin, {
    types: [namespace_1.cc.Job, namespace_1.cc.TransformJob],
    actionStatus: rdf_ns_builders_1.schema.PotentialActionStatus,
});
function PublishJobMixin(base) {
    class Impl extends (0, rdfs_1.ResourceMixin)((0, schema_1.ActionMixin)(base)) {
        get publishedTo() {
            return this.pointer
                .out(rdf_ns_builders_1.schema.workExample)
                .filter(example => example.out(rdf_ns_builders_1.schema.encodingFormat).terms.length === 0)
                .term;
        }
        set publishedTo(term) {
            if (term) {
                this.pointer.addOut(rdf_ns_builders_1.schema.workExample, term);
            }
        }
        get workExamples() {
            return this.pointer
                .out(rdf_ns_builders_1.schema.workExample)
                .filter(example => example.out(rdf_ns_builders_1.schema.encodingFormat).terms.length > 0)
                .map(workExampleP => new schema_1.CreativeWorkMixin.Class(workExampleP));
        }
        addWorkExample(attrs) {
            return this.pointer.addOut(rdf_ns_builders_1.schema.workExample, workExample => {
                workExample
                    .addOut(rdf_ns_builders_1.rdf.type, rdf_ns_builders_1.schema.CreativeWork)
                    .addOut(rdf_ns_builders_1.schema.name, attrs.name)
                    .addOut(rdf_ns_builders_1.schema.url, attrs.url)
                    .addOut(rdf_ns_builders_1.schema.encodingFormat, attrs.encodingFormat);
            });
        }
    }
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.cc.project })
    ], Impl.prototype, "project", void 0);
    __decorate([
        rdfine_1.property.literal({ path: namespace_1.cc.revision, type: Number })
    ], Impl.prototype, "revision", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.cc.publishGraph })
    ], Impl.prototype, "publishGraph", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: rdf_ns_builders_1.schema.creativeWorkStatus })
    ], Impl.prototype, "status", void 0);
    __decorate([
        rdfine_1.property.literal({ path: rdf_ns_builders_1.schema.query })
    ], Impl.prototype, "query", void 0);
    return Impl;
}
exports.PublishJobMixin = PublishJobMixin;
PublishJobMixin.appliesTo = namespace_1.cc.PublishJob;
exports.createPublish = (0, initializer_1.initializer)(PublishJobMixin, {
    types: [namespace_1.cc.Job, namespace_1.cc.PublishJob],
    actionStatus: rdf_ns_builders_1.schema.PotentialActionStatus,
});
function UnlistJobMixin(base) {
    class Impl extends (0, rdfs_1.ResourceMixin)((0, schema_1.ActionMixin)(base)) {
    }
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.cc.project })
    ], Impl.prototype, "project", void 0);
    __decorate([
        (0, rdfine_1.property)({ path: namespace_1.cc.publishGraph })
    ], Impl.prototype, "publishGraph", void 0);
    return Impl;
}
exports.UnlistJobMixin = UnlistJobMixin;
UnlistJobMixin.appliesTo = namespace_1.cc.UnlistJob;
exports.createUnlist = (0, initializer_1.initializer)(UnlistJobMixin, {
    types: [namespace_1.cc.Job, namespace_1.cc.UnlistJob],
    actionStatus: rdf_ns_builders_1.schema.PotentialActionStatus,
});
