"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const vue_1 = require("vue");
const vuex_1 = require("vuex");
require("@/customElements/HydraOperationForm");
const use_dialog_1 = require("@/use-dialog");
const use_hydra_form_1 = require("@/use-hydra-form");
const use_toast_1 = require("@/use-toast");
exports.default = (0, vue_1.defineComponent)({
    name: 'JobForm',
    props: {
        operation: {
            type: Object,
            required: true,
        },
        submitButtonVariant: {
            type: String,
            default: undefined,
        },
        confirm: {
            type: Boolean,
            default: false,
        },
        confirmationMessage: {
            type: String,
            default: 'Are you sure?',
        },
    },
    setup(props) {
        var _a;
        const { operation, confirm, confirmationMessage } = (0, vue_1.toRefs)(props);
        const store = (0, vuex_1.useStore)();
        const askConfirmation = async () => {
            return new Promise((resolve) => {
                (0, use_dialog_1.confirmDialog)({
                    title: operation.value.title,
                    message: confirmationMessage.value,
                    confirmText: 'Confirm',
                    onConfirm() {
                        resolve(true);
                    },
                    onCancel() {
                        resolve(false);
                    },
                });
            });
        };
        const form = (0, use_hydra_form_1.useHydraForm)(operation, {
            async beforeSubmit() {
                if (!confirm.value)
                    return true;
                return await askConfirmation();
            },
            afterSubmit(job) {
                (0, use_toast_1.displayToast)({
                    message: `${job.name} was started`,
                    variant: 'success',
                });
                store.dispatch('project/fetchJobCollection');
            },
        });
        const resourceType = operation.value.expects.find((expect) => !expect.types.has(rdf_ns_builders_1.sh.Shape));
        if (resourceType) {
            (_a = form.resource.value) === null || _a === void 0 ? void 0 : _a.addOut(rdf_ns_builders_1.rdf.type, resourceType.id);
        }
        return form;
    },
});
