"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'RadioButton',
    props: {
        modelValue: {
            type: String,
            default: undefined,
        },
        nativeValue: {
            type: String,
            default: undefined,
        },
        size: {
            type: String,
            default: undefined,
        },
    },
    emits: ['update:modelValue'],
    computed: {
        isSelected() {
            return this.modelValue === this.nativeValue;
        },
    }
});
