"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    name: 'BLoading',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        isFullPage: {
            type: Boolean,
            default: true,
        },
        animation: {
            type: String,
            default: 'fade',
        }
    },
});
