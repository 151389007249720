"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vuex_1 = require("vuex");
exports.default = (0, vue_1.defineComponent)({
    name: 'MaterializeJobs',
    computed: {
        ...(0, vuex_1.mapGetters)('project', {
            materializeLabel: 'materializeLabel',
        }),
    },
});
