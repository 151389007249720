"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const namespace_1 = require("@cube-creator/core/namespace");
exports.default = (0, vue_1.defineComponent)({
    name: 'JobStatus',
    props: {
        job: {
            type: Object,
            required: true,
        },
        showLabel: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        statusString() {
            var _a, _b;
            return (_b = (_a = this.job.actionStatus) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 'unknown';
        },
        icon() {
            var _a;
            return (_a = {
                [namespace_1.cc.CanceledJobStatus.value]: 'times-circle',
                [rdf_ns_builders_1.schema.CompletedActionStatus.value]: 'check-circle',
                [rdf_ns_builders_1.schema.PotentialActionStatus.value]: 'circle',
                [rdf_ns_builders_1.schema.ActiveActionStatus.value]: 'circle',
                [rdf_ns_builders_1.schema.FailedActionStatus.value]: 'times-circle',
            }[this.statusString]) !== null && _a !== void 0 ? _a : 'question-circle';
        },
        iconColorClass() {
            var _a;
            return (_a = {
                [namespace_1.cc.CanceledJobStatus.value]: 'has-text-warning',
                [rdf_ns_builders_1.schema.CompletedActionStatus.value]: 'has-text-success',
                [rdf_ns_builders_1.schema.PotentialActionStatus.value]: 'has-text-grey-light blink',
                [rdf_ns_builders_1.schema.ActiveActionStatus.value]: 'has-text-info blink',
                [rdf_ns_builders_1.schema.FailedActionStatus.value]: 'has-text-danger',
            }[this.statusString]) !== null && _a !== void 0 ? _a : 'has-text-dark';
        },
        typeClass() {
            var _a;
            return (_a = {
                [rdf_ns_builders_1.schema.CompletedActionStatus.value]: 'is-success',
                [rdf_ns_builders_1.schema.PotentialActionStatus.value]: 'is-light',
                [rdf_ns_builders_1.schema.ActiveActionStatus.value]: 'is-info',
                [rdf_ns_builders_1.schema.FailedActionStatus.value]: 'is-danger',
            }[this.statusString]) !== null && _a !== void 0 ? _a : 'is-default';
        },
        label() {
            var _a;
            return (_a = {
                [rdf_ns_builders_1.schema.CompletedActionStatus.value]: 'Complete',
                [rdf_ns_builders_1.schema.PotentialActionStatus.value]: 'Pending',
                [rdf_ns_builders_1.schema.ActiveActionStatus.value]: 'Running',
                [rdf_ns_builders_1.schema.FailedActionStatus.value]: 'Failed',
            }[this.statusString]) !== null && _a !== void 0 ? _a : 'Unknown';
        },
    },
});
