"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const languages_1 = require("@cube-creator/core/languages");
exports.default = (0, vue_1.defineComponent)({
    name: 'LanguageSelect',
    props: {
        selectedLanguage: {
            type: String,
            default: 'en'
        }
    },
    emits: ['language-selected'],
    data() {
        return {
            languages: languages_1.supportedLanguages.map(({ value }) => value),
        };
    }
});
