"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const rdf_properties_1 = require("@/rdf-properties");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
exports.default = (0, vue_1.defineComponent)({
    name: 'TermDisplay',
    props: {
        term: {
            type: Object,
            required: true,
        },
        showLanguage: {
            type: Boolean,
            default: false,
        },
        base: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        displayShort() {
            return this.__commonTermPrefixes || this.__rawLabel;
        },
        displayFull() {
            if (this.term.termType === 'Literal') {
                const datatype = this.term.datatype ? `^^${(0, rdf_properties_1.shrink)(this.term.datatype.value)}` : '';
                const language = this.term.language ? `@${this.term.language}` : '';
                return `"${this.term.value}${language}"${datatype}`;
            }
            else {
                return this.term.value;
            }
        },
        __commonTermPrefixes() {
            const shrunk = (0, rdf_properties_1.shrink)(this.term.value);
            return shrunk !== this.term.value ? shrunk : null;
        },
        __rawLabel() {
            if (this.term.termType === 'NamedNode') {
                return (0, rdf_properties_1.shrink)(this.term.value, this.base);
            }
            if (this.term.termType === 'Literal') {
                return formatLiteral(this.term);
            }
            return this.term.value;
        },
    },
});
function formatLiteral(term) {
    if (rdf_ns_builders_1.xsd.dateTime.equals(term.datatype)) {
        return new Date(term.value).toLocaleString();
    }
    return term.value;
}
