"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("@/api");
const ns = __importStar(require("@cube-creator/core/namespace"));
const serializers_1 = require("../serializers");
const initialState = {
    collection: null,
    details: {},
};
const getters = {
    getProjectDetails(state) {
        return (project) => {
            var _a;
            return (_a = state.details[project.id.value]) !== null && _a !== void 0 ? _a : null;
        };
    }
};
const actions = {
    async fetchCollection(context, query) {
        var _a;
        const entrypoint = context.rootState.api.entrypoint;
        const collectionURI = (_a = entrypoint === null || entrypoint === void 0 ? void 0 : entrypoint.get(ns.cc.projects)) === null || _a === void 0 ? void 0 : _a.id;
        if (!collectionURI)
            throw new Error('Missing projects collection in entrypoint');
        let params = new URLSearchParams();
        if (query) {
            params = new URLSearchParams(query);
        }
        else if (location.search) {
            params = new URLSearchParams(location.search);
        }
        const collection = await api_1.api.fetchResource(collectionURI.value + '?' + params.toString());
        context.commit('storeCollection', collection);
    },
    async fetchProjectDetails(context, project) {
        const details = await api_1.api.fetchResource(project.details.id.value);
        context.commit('storeProjectDetails', { project, details });
        return details;
    },
};
const mutations = {
    storeCollection(state, collection) {
        state.collection = collection ? (0, serializers_1.serializeProjectsCollection)(collection) : null;
    },
    storeProjectDetails(state, { project, details }) {
        state.details = {
            ...state.details,
            [project.id.value]: (0, serializers_1.serializeProjectDetails)(details),
        };
    },
};
exports.default = {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
