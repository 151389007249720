"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("@/api");
const serializers_1 = require("../serializers");
const namespace_1 = require("@cube-creator/core/namespace");
const getInitialState = () => ({
    createIdentifier: null,
    project: null,
    csvMapping: null,
    sourcesCollection: null,
    sources: {},
    tableCollection: null,
    tables: {},
    cubeMetadata: null,
    dimensionMetadataCollection: null,
    jobCollection: null,
});
const getters = {
    hasCSVMapping(state) {
        var _a;
        return !!((_a = state.project) === null || _a === void 0 ? void 0 : _a.csvMapping);
    },
    materializeLabel(state, getters) {
        return getters.hasCSVMapping ? 'transformation' : 'import';
    },
    sources(state, getters) {
        return Object.values(state.sources)
            .map(source => {
            const tables = getters.getSourceTables(source);
            const hasObservationTable = tables.some(table => table.isObservationTable);
            return { source, hasObservationTable };
        })
            .sort(({ hasObservationTable: o1 }, { hasObservationTable: o2 }) => (o1 === o2) ? 0 : (o1 ? -1 : 1))
            .map(({ source }) => source);
    },
    tables(state) {
        return Object.values(state.tables)
            .sort(({ isObservationTable: o1 }, { isObservationTable: o2 }) => (o1 === o2) ? 0 : (o1 ? -1 : 1));
    },
    dimensions(state) {
        var _a;
        const dimensions = ((_a = state.dimensionMetadataCollection) === null || _a === void 0 ? void 0 : _a.hasPart) || [];
        // Sort dimensions with the following priority:
        //   sh:order / measure dimension / key dimension / property (alphabetically)
        return dimensions.sort((dim1, dim2) => {
            var _a, _b;
            return ((((_a = dim1.order) !== null && _a !== void 0 ? _a : Infinity) - ((_b = dim2.order) !== null && _b !== void 0 ? _b : Infinity))) ||
                ((dim1.isMeasureDimension ? 1 : Infinity) - (dim2.isMeasureDimension ? 1 : Infinity)) ||
                ((dim1.isKeyDimension ? 1 : Infinity) - (dim2.isKeyDimension ? 1 : Infinity)) ||
                dim1.about.value.localeCompare(dim2.about.value);
        });
    },
    findSource(_state, getters) {
        return (id) => getters.sources.find(({ clientPath }) => clientPath === id);
    },
    getSource(state) {
        return (uri) => {
            const source = state.sources[uri.value];
            if (!source)
                throw new Error(`Source not found: ${uri.value}`);
            return source;
        };
    },
    getSourceTables(state, getters) {
        return (source) => getters.tables.filter((table) => { var _a; return source.id.equals((_a = table.csvSource) === null || _a === void 0 ? void 0 : _a.id); });
    },
    findTable(_state, getters) {
        return (id) => getters.tables.find(({ clientPath }) => clientPath === id);
    },
    getTable(state) {
        return (uri) => {
            const table = state.tables[uri.value];
            if (!table)
                throw new Error(`Table not found: ${uri.value}`);
            return table;
        };
    },
    columnMappings(state, getters) {
        return getters.tables.map((table) => table.columnMappings).flat();
    },
    findColumnMapping(_state, getters) {
        return (id) => getters.columnMappings.find(({ clientPath }) => clientPath === id);
    },
    findDimension(_state, getters) {
        return (id) => getters.dimensions.find(({ clientPath }) => clientPath === id);
    },
    jobs(state) {
        var _a, _b;
        const jobs = (_b = (_a = state.jobCollection) === null || _a === void 0 ? void 0 : _a.member) !== null && _b !== void 0 ? _b : [];
        return jobs.sort(({ created: created1 }, { created: created2 }) => created2.getTime() - created1.getTime());
    },
    findJob(_state, getters) {
        return (id) => getters.jobs.find(({ clientPath }) => clientPath === id);
    },
    transformJobs(state, getters) {
        return getters.jobs.filter((job) => job.types.has(namespace_1.cc.TransformJob) || job.types.has(namespace_1.cc.ImportJob));
    },
    publicationJobs(state, getters) {
        return getters.jobs.filter((job) => job.types.has(namespace_1.cc.PublishJob) || job.types.has(namespace_1.cc.UnlistJob));
    },
};
const actions = {
    async fetchProject(context, id) {
        context.commit('storeProject', null);
        const project = await api_1.api.fetchResource(id);
        context.commit('storeProject', project);
        context.commit('storeJobCollection', null);
        context.dispatch('fetchJobCollection');
        return project;
    },
    reset(context) {
        context.commit('reset');
    },
    async fetchCSVMapping(context) {
        var _a;
        const project = context.state.project;
        if (!project) {
            throw new Error('Project not loaded');
        }
        const mappingId = (_a = project.csvMapping) === null || _a === void 0 ? void 0 : _a.id.value;
        if (!mappingId) {
            throw new Error('Project does not have a csvMapping');
        }
        context.commit('storeCSVMapping', null);
        context.commit('storeSourcesCollection', null);
        context.commit('storeTableCollection', null);
        const mapping = await api_1.api.fetchResource(mappingId);
        context.commit('storeCSVMapping', mapping);
        api_1.api.fetchResource(mapping.sourcesCollection.id.value).then((sourcesCollection) => context.commit('storeSourcesCollection', sourcesCollection));
        api_1.api.fetchResource(mapping.tableCollection.id.value).then((tableCollection) => context.commit('storeTableCollection', tableCollection));
        return mapping;
    },
    async fetchJobCollection(context) {
        var _a;
        if (!context.state.project) {
            throw new Error('Project not loaded');
        }
        const id = (_a = context.state.project.jobCollection) === null || _a === void 0 ? void 0 : _a.id.value;
        if (!id) {
            throw new Error('Project does not have a jobCollection');
        }
        const collection = await api_1.api.fetchResource(id);
        context.commit('storeJobCollection', collection);
        return collection;
    },
    async refreshSourcesCollection(context) {
        const collection = context.state.sourcesCollection;
        if (!collection) {
            throw new Error('Sources collection not loaded');
        }
        context.commit('storeSourcesCollection', null);
        const freshCollection = await api_1.api.fetchResource(collection.id.value);
        context.commit('storeSourcesCollection', freshCollection);
        return freshCollection;
    },
    async refreshTableCollection(context) {
        const collection = context.state.tableCollection;
        if (!collection) {
            throw new Error('Table collection not loaded');
        }
        context.commit('storeTableCollection', null);
        const freshCollection = await api_1.api.fetchResource(collection.id.value);
        context.commit('storeTableCollection', freshCollection);
        return freshCollection;
    },
    async fetchCubeMetadata(context) {
        const project = context.state.project;
        if (!project) {
            throw new Error('Project not loaded');
        }
        if (!project.dataset) {
            throw new Error('Project does not have a cc:dataset');
        }
        context.commit('storeCubeMetadata', null);
        const cubeMetadata = await api_1.api.fetchResource(project.dataset.id.value);
        context.commit('storeCubeMetadata', cubeMetadata);
        return cubeMetadata;
    },
    async fetchDimensionMetadataCollection(context) {
        const cubeMetadata = context.state.cubeMetadata;
        if (!cubeMetadata) {
            throw new Error('CubeMetadata not loaded');
        }
        context.commit('storeDimensionMetadataCollection', null);
        return context.dispatch('refreshDimensionMetadataCollection');
    },
    async refreshDimensionMetadataCollection(context) {
        const cubeMetadata = context.state.cubeMetadata;
        if (!cubeMetadata) {
            throw new Error('CubeMetadata not loaded');
        }
        const collection = await api_1.api.fetchResource(cubeMetadata.dimensionMetadata.id.value);
        context.commit('storeDimensionMetadataCollection', collection);
        return collection;
    },
};
const mutations = {
    storeProject(state, project) {
        state.project = Object.freeze(project);
        if (project && 'cubeIdentifier' in project) {
            const { cubeIdentifier } = project;
            state.createIdentifier = (termName) => {
                return project.maintainer.createIdentifier({
                    termName,
                    cubeIdentifier,
                }).value;
            };
        }
        else {
            state.createIdentifier = () => {
                throw new Error('Project does not have a cube identifier');
            };
        }
    },
    storeCSVMapping(state, mapping) {
        state.csvMapping = Object.freeze(mapping);
    },
    storeSourcesCollection(state, collection) {
        var _a;
        state.sourcesCollection = collection ? (0, serializers_1.serializeSourcesCollection)(collection) : null;
        state.sources = indexResources(((_a = state.sourcesCollection) === null || _a === void 0 ? void 0 : _a.member) || [], ({ id }) => id.value);
    },
    storeTableCollection(state, collection) {
        var _a;
        state.tableCollection = collection ? (0, serializers_1.serializeTableCollection)(collection) : null;
        state.tables = indexResources(((_a = state.tableCollection) === null || _a === void 0 ? void 0 : _a.member) || [], ({ id }) => id.value);
    },
    storeTable(state, table) {
        state.tables[table.id.value] = (0, serializers_1.serializeTable)(table);
    },
    storeNewColumnMapping(state, { table, columnMapping }) {
        const storedTable = state.tables[table.id.value];
        if (!storedTable)
            throw new Error(`Table not found: ${table.id.value}`);
        const serializedColumnMapping = (0, serializers_1.serializeColumnMapping)(columnMapping);
        storedTable.columnMappings.push(serializedColumnMapping);
    },
    storeUpdatedColumnMapping(state, { table, columnMapping }) {
        const storedTable = state.tables[table.id.value];
        if (!storedTable)
            throw new Error(`Table not found: ${table.id.value}`);
        const serializedColumnMapping = (0, serializers_1.serializeColumnMapping)(columnMapping);
        const index = storedTable.columnMappings.findIndex(({ id }) => id.equals(columnMapping.id));
        storedTable.columnMappings[index] = serializedColumnMapping;
    },
    storeCubeMetadata(state, cubeMetadata) {
        state.cubeMetadata = cubeMetadata ? (0, serializers_1.serializeCubeMetadata)(cubeMetadata) : null;
    },
    storeDimensionMetadataCollection(state, collection) {
        state.dimensionMetadataCollection = collection ? (0, serializers_1.serializeDimensionMetadataCollection)(collection) : null;
    },
    storeJobCollection(state, collection) {
        state.jobCollection = collection ? (0, serializers_1.serializeJobCollection)(collection) : null;
    },
    reset(state) {
        Object.assign(state, getInitialState());
    },
};
exports.default = {
    namespaced: true,
    state: getInitialState(),
    getters,
    actions,
    mutations,
};
function indexResources(array, indexFunction) {
    return array.reduce((acc, item) => {
        return {
            ...acc,
            [indexFunction(item)]: item,
        };
    }, {});
}
