"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
require("bulma-quickview/dist/css/bulma-quickview.min.css");
exports.default = (0, vue_1.defineComponent)({
    name: 'SidePane',
    props: {
        isOpen: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    emits: ['close'],
    data() {
        return {
            width: null,
        };
    },
    methods: {
        onMouseDown() {
            const containerWidth = window.innerWidth;
            const onMouseMove = ({ pageX }) => {
                this.width = `${containerWidth - pageX}px`;
            };
            const onMouseUp = () => {
                window.removeEventListener('mousemove', onMouseMove);
                window.removeEventListener('mouseup', onMouseUp);
            };
            window.addEventListener('mousemove', onMouseMove);
            window.addEventListener('mouseup', onMouseUp);
        },
    },
});
