"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePolling = void 0;
const vue_1 = require("vue");
function usePolling(poll, interval = 3000) {
    const poller = (0, vue_1.ref)(null);
    const stopPolling = () => {
        if (poller.value) {
            window.clearInterval(poller.value);
        }
    };
    const doPoll = async () => {
        try {
            await poll();
        }
        catch (e) {
            stopPolling();
            throw e;
        }
    };
    const setupPolling = () => {
        poller.value = window.setInterval(doPoll, interval);
    };
    (0, vue_1.onMounted)(setupPolling);
    (0, vue_1.onBeforeUnmount)(stopPolling);
    return {
        poller
    };
}
exports.usePolling = usePolling;
