"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APIErrorAuthorization = exports.APIPayloadTooLarge = exports.APIErrorConflict = exports.APIErrorValidation = exports.APIErrorNotFound = exports.APIError = void 0;
class APIError extends Error {
    constructor(details, response) {
        const message = (details === null || details === void 0 ? void 0 : details.title) || 'Unknown error';
        super(message);
        this.details = details;
        this.response = response;
    }
    static async fromResponse(response) {
        const httpResponse = response === null || response === void 0 ? void 0 : response.response;
        let details;
        try {
            details = await (httpResponse === null || httpResponse === void 0 ? void 0 : httpResponse.xhr.json());
        }
        catch (e) {
            details = null;
        }
        switch (httpResponse === null || httpResponse === void 0 ? void 0 : httpResponse.xhr.status) {
            case 404:
                return new APIErrorNotFound(details, response);
            case 400:
                return new APIErrorValidation(details, response);
            case 409:
                return new APIErrorConflict(details, response);
            case 413:
                return new APIPayloadTooLarge(details, response);
            case 401:
            case 403:
                return new APIErrorAuthorization(details, response);
            default:
                return new APIError(details, response);
        }
    }
}
exports.APIError = APIError;
class APIErrorNotFound extends APIError {
}
exports.APIErrorNotFound = APIErrorNotFound;
class APIErrorValidation extends APIError {
}
exports.APIErrorValidation = APIErrorValidation;
class APIErrorConflict extends APIError {
}
exports.APIErrorConflict = APIErrorConflict;
class APIPayloadTooLarge extends APIError {
}
exports.APIPayloadTooLarge = APIPayloadTooLarge;
class APIErrorAuthorization extends APIError {
}
exports.APIErrorAuthorization = APIErrorAuthorization;
