"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.auth = void 0;
const cookie_storage_1 = require("cookie-storage");
const vuex_oidc_1 = require("vuex-oidc");
const oidc_client_1 = require("oidc-client");
const oidc = window.APP_CONFIG.oidc;
const base = new URL(process.env.BASE_URL || '/', window.location.toString());
const cookieStorage = new cookie_storage_1.CookieStorage({
    path: base.pathname,
    domain: base.hostname,
    secure: base.protocol === 'https:',
    sameSite: 'Strict',
});
const oidcSettings = {
    redirectUri: new URL('oidc-callback', base).toString(),
    postLogoutRedirectUri: new URL('logout', base).toString(),
    responseType: 'code',
    automaticSilentRenew: true,
    stateStore: new oidc_client_1.WebStorageStateStore({ store: cookieStorage }),
    monitorSession: false,
    ...oidc,
};
function auth() {
    return (0, vuex_oidc_1.vuexOidcCreateStoreModule)(oidcSettings, {
        namespaced: true,
        publicRoutePaths: ['/oidc-error', '/logout'],
        routeBase: process.env.BASE_URL,
        dispatchEventsOnWindow: true,
    });
}
exports.auth = auth;
