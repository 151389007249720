"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("@/api");
const rdf_ns_builders_1 = require("@tpluscode/rdf-ns-builders");
const initialState = {
    entrypoint: null,
};
const getters = {
    publicQueryEndpoint(state) {
        var _a;
        return ((_a = state.entrypoint) === null || _a === void 0 ? void 0 : _a.pointer.out(rdf_ns_builders_1.dcat.endpointURL).value) || null;
    }
};
const actions = {
    async fetchEntrypoint(context) {
        const entrypoint = await api_1.api.fetchResource('/');
        context.commit('storeEntrypoint', entrypoint);
        return entrypoint;
    },
    async invokeDeleteOperation(context, { operation, successMessage, callbackAction, callbackParams = {} }) {
        context.commit('app/setLoading', true, { root: true });
        try {
            await api_1.api.invokeDeleteOperation(operation);
            context.dispatch('app/showMessage', {
                message: successMessage,
                variant: 'success',
            }, { root: true });
            if (callbackAction) {
                context.dispatch(callbackAction, callbackParams, { root: true });
            }
        }
        catch (e) {
            context.dispatch('app/showMessage', {
                title: 'An error occurred',
                message: `${e}`,
                variant: 'danger',
            }, { root: true });
            throw e;
        }
        finally {
            context.commit('app/setLoading', false, { root: true });
        }
    },
};
const mutations = {
    storeEntrypoint(state, entrypoint) {
        state.entrypoint = Object.freeze(entrypoint);
    }
};
exports.default = {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
