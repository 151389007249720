"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const api_1 = require("@/api");
const namespace_1 = require("@cube-creator/core/namespace");
const initialState = {
    entrypoint: null,
    collection: null,
};
const getters = {
    dimensions(state) {
        var _a, _b;
        return (_b = (_a = state.collection) === null || _a === void 0 ? void 0 : _a.member) !== null && _b !== void 0 ? _b : [];
    },
};
const actions = {
    async fetchEntrypoint(context) {
        var _a;
        const rootEntrypoint = context.rootState.api.entrypoint;
        const entrypointURI = (_a = rootEntrypoint === null || rootEntrypoint === void 0 ? void 0 : rootEntrypoint.get(namespace_1.cc.sharedDimensions)) === null || _a === void 0 ? void 0 : _a.id;
        if (!entrypointURI)
            throw new Error('Shared dimensions entrypoint URI not found');
        const entrypoint = await api_1.api.fetchResource(entrypointURI.value);
        context.commit('storeEntrypoint', entrypoint);
        return entrypoint;
    },
    async fetchCollection(context) {
        var _a;
        const entrypoint = context.state.entrypoint;
        const collectionURI = (_a = entrypoint === null || entrypoint === void 0 ? void 0 : entrypoint.get(namespace_1.md.sharedDimensions)) === null || _a === void 0 ? void 0 : _a.id;
        if (!collectionURI)
            throw new Error('Missing shared dimensions collection in entrypoint');
        const collection = await api_1.api.fetchResource(collectionURI.value);
        context.commit('storeCollection', collection);
    },
};
const mutations = {
    storeEntrypoint(state, entrypoint) {
        state.entrypoint = Object.freeze(entrypoint);
    },
    storeCollection(state, collection) {
        state.collection = collection;
    },
};
exports.default = {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
